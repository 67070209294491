<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
@import './views/common/reset/reset.scss';
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
  #app{
    padding-top: 68px;
  }


</style>
