import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
let routes = [
  {
    path: '/',
    meta: { title: '首页', type: 'pc' },
    name: 'home',
    component: () => import('@/views/homePage/index'),
  },
  {
    path: '/solution',
    meta: { title: '解决方案', type: 'pc' },
    name: 'soliuton',
    component: () => import('@/views/solution/index'),
  },
  {
    path: '/trade',
    meta: { title: '解决方案', type: 'pc' },
    name: 'trade',
    component: () => import('@/views/solution/trade'),
  },
  {
    path: '/financialModel',
    meta: { title: '解决方案', type: 'pc' },
    name: 'financialModel',
    component: () => import('@/views/solution/financialModel'),
  },
  {
    path: '/gjcr',
    meta: { title: '解决方案', type: 'pc' },
    name: 'gjcr',
    component: () => import('@/views/solution/gjcr'),
  },
  {
    path: '/cooperation',
    meta: { title: '解决方案', type: 'pc' },
    name: 'cooperation',
    component: () => import('@/views/solution/cooperation'),
  },
  {
    path: '/local',
    meta: { title: '解决方案', type: 'pc' },
    name: 'local',
    component: () => import('@/views/solution/local'),
  },
  {
    path: '/comprehensive',
    meta: { title: '综合金融', type: 'pc' },
    name: 'comprehensive',
    component: () => import('@/views/platformServices/comprehensive'),
  },
  {
    path: '/financialRegion',
    meta: { title: '区域', type: 'pc' },
    name: 'financialRegion',
    component: () => import('@/views/platformServices/financialRegion'),
  },
  {
    path: '/mechanism',
    meta: { title: '机构', type: 'pc' },
    name: 'mechanism',
    component: () => import('@/views/platformServices/mechanism'),
  },
  {
    path: '/atasPage',
    meta: { title: '图集区域', type: 'pc' },
    name: 'atasPage',
    component: () => import('@/views/special/atlasPage'),
  },
  {
    path: '/topicDetails',
    meta: { title: '专题详情', type: 'pc' },
    name: 'topicDetails',
    component: () => import('@/views/special/topicDetails'),
  },
  {
    path: '/topicDetailsList',
    meta: { title: '专题详情列表', type: 'pc' },
    name: 'topicDetailsList',
    component: () => import('@/views/special/topicDetailsList'),
  },
  {
    path: '/news',
    meta: { title: '新闻详情', type: 'pc' },
    name: 'news',
    component: () => import('@/views/news/news'),
  },
  {
    path: '/newslist',
    meta: { title: '新闻详情列表', type: 'pc' },
    name: 'newslist',
    component: () => import('@/views/news/newsList'),
  },
  {
    path: '/about',
    meta: { title: '关于我们', type: 'pc' },
    name: 'about',
    component: () => import('@/views/abouts/index'),
  },
  {
    path: '/partner',
    meta: { title: '合作伙伴', type: 'pc' },
    name: 'partner',
    component: () => import('@/views/partner/partner'),
  },
  {
    path: '/transaction',
    meta: { title: '交易平台', type: 'pc' },
    name: 'transaction',
    component: () => import('@/views/transaction/transaction'),
  },
  {
    path: '/pricingEngine',
    meta: { title: '定价引擎', type: 'pc' },
    name: 'pricingEngine',
    component: () => import('@/views/pricing-engine/pricingEngine'),
  },
  {
    path: '/manageSystem',
    meta: { title: '管理系统', type: 'pc' },
    name: 'manageSystem',
    component: () => import('@/views/manage-system/manageSystem'),
  },
]

const router = new Router({
  mode: 'hash',
  routes,
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    if(to.meta.title == "首页" || to.meta.title == "合作伙伴" || to.meta.title == "关于我们" ) {
      document.title = "华炫鼎盛（北京）科技有限公司"
    } else {
      document.title = to.meta.title + "-华炫鼎盛"
    }
    next()
  }
})
router.afterEach(() => {
  window.scrollTo(0, 0)
})


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
